import React from 'react'
import './css.css';

const test = () =>
    <>
        <p className={'fontSize18 fontFamilyCalibri margin-top:0cm;margin-bottom:8.0pt; lineHeight107 bold'}>Terms of Service</p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>TERMS OF USE</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> All ECG compliant company data can be found at https://firmen.wko.at</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> The use of this mediaLab Performance GmbH ("mediaLab") website is governed by these Terms and Conditions of Use. mediaLab has tasked Classerium A/S (Forskerparken 10, 5230 Odense M, Denmark, EU company number: DK41023414) with the technical operation of the website. Users of this website agree to these Terms and Conditions and agree that mediaLab cannot be held liable for usage of this website. mediaLab reserves the right to amend and update these Terms and Conditions at any time.<br /><br />
        mediaLab will do its best to present correct and current information on this website. Details and information on this website are, however, without guarantee. Users of this website therefore agree to access and use this website and its contents at their own risk. mediaLab is not liable for any damages arising from the access and use of or impossibility of using this website and is further not liable for any errors or exclusions.<br /><br />
        All image, text and sound files as well as animations, videos, etc., and all other parts of this website are protected by copyright and may not be – either in whole or in part – distributed, downloaded, amended, re-used, redirected or otherwise used without prior express written authorisation. Any files available for download may only be used for private purposes.<br /><br />
        mediaLab reserves the right to exclude people from the service and/or from the mail service if there is good reason for this.<br /><br />
        mediaLab is only liable for possible illegal activities or information from electronic links that lead from this site to other sites on the worldwide web, as well as for content that is created by users on our platform, if it was actually aware of it and in cases where mediaLab does not immediately remove the information or block access to it. Content of a discriminatory, offensive, morally reprehensible or illegal nature can be removed by mediaLab at any time.<br /><br />
        For any further questions regarding these Terms and Conditions of Use and the General Terms and Conditions, please contact us at:<br /><br />
        mediaLab Performance GmbH<br />
Wilhelmstraße 68<br />
1120 Vienna<br />
Austria<br />
E-mail: office@medialab.world<br /><br />
GmbH based in Vienna<br />
registered under FN 526639w of the Commercial Court Vienna<br /><br />
            <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>DISCLAIMER</span></p>
1.	mediaLab Performance GmbH ("mediaLab") assumes no liability of any kind for the status, correctness, completeness, legality and/or quality of the information provided. This also applies to all products and services offered on this website, including the product descriptions, prices, and information provided about mediaLab contract partners. Technical errors and/or maintenance work, as well as other causes (such as re-launch of the website) can lead to a brief or longer loss of access to the website. mediaLab cannot guarantee access to and usability of the website. mediaLab is not liable for any tangible or intangible damages (such as a loss of profits, reliance losses, damages due to loss of data, claims based on unjust enrichment, legal fees, or contract brokerage fees) that occur through use of the information provided or use of incorrect or incomplete information, unless mediaLab causes the damage intentionally or due to gross negligence. All offers are subject to change and non-binding. mediaLab expressly reserves the right to amend, supplement or delete this website either in whole or in part, or to halt publication thereof either permanently or temporarily, without any notice.<br /><br />
2.	Furthermore, mediaLab is not responsible for hyperlinks or their content. mediaLab assumes no liability of any kind for the status, correctness, completeness, legality and/or quality of hyperlinks. Any liability lies with the provider of the linked website. mediaLab has no control over the current or future structure, content or authorship of these hyperlinks. Therefore, mediaLab expressly distances itself from the contents of all hyperlinks that have been changed since the link was added, as well as from entries made by third parties on mediaLab's platform and mailing lists. Ongoing control of linked websites’ content is not reasonable without substantial indication of legal infringements, but as soon as mediaLab becomes aware of such legal infringement, the hyperlink will be removed immediately.<br /><br />
3.	Use of this Website is permitted exclusively for information purposes and private and professional purposes. All contents of this website are protected by copyright. This applies regardless of whether the contents are provided for a fee or free of charge. Any type of duplication, use, renting, lending, publication, or other use of any type at all is not permitted without express written permission from mediaLab and contract partners. Violation of this requirement may result in legal consequences, particularly under trademark, copyright and competition law. mediaLab explicitly retains all copyright, trademark rights and usage rights (rights to use work and permission to use work) in this website.<br /><br />
4.	mediaLab places great value on the careful and confidential handling of personal data. If the user is able to enter personal or business data (e-mail addresses, names, addresses) on the website, the user discloses such data on an expressly voluntary basis.<br /><br />
5.	This disclaimer shall be deemed a component of the website. If parts or individual phrases in this document do not, do not fully or no longer correspond with the current legal situation, the content and validity of the remaining parts of this document remain unaffected.<br /><br />
6.	Furthermore, mediaLab is not liable for user-generated content published on its website. mediaLab is not liable for any damages arising from such content. The author of comments/reviews of any kind published on the website is solely liable for their contribution. In the event of a violation of third party rights, the person responsible for the violation shall fully indemnify and release mediaLab from liability. mediaLab reserves the right to delete any illegal or immoral content or any other content deemed by mediaLab to be unsuitable; no claims may be made against mediaLab in this regard.<br /><br />

        </span></p>

    </>

export default test; 