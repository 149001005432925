import React from 'react';
import localizations from '../../localizations';
import './NextPage.css';

const getLocalizations = (language) =>
  Object.keys(localizations).find((key) => key === language)
    ? localizations[language]
    : localizations.en;

const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce((r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r), {});

function NextPage({ lang, setNextPage }) {
  const localizations = getLocalizations(lang);

  const submit = () => {
    const { r, wtype } = getQueryParams();
    const webinarId = getLocalizations(lang).webinarId;
    const url = `https://www.webinarworld.com/${lang}/register/${webinarId}?r=${encodeURIComponent(
      r,
    )}&wtype=instant`;

    window.open(url);
  };

  return (
    <>
      <div className="Form">
        <h2 className="title">{localizations.reply1}</h2>
        <h5 className="subtitle">
          {localizations.reply2}
          <br />
        </h5>

        <div className="button" onClick={submit}>
          {localizations.linkTitle}
        </div>
      </div>
    </>
  );
}

export default NextPage;
