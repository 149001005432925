import React from 'react';
import './App.css';
import Toolbar from '../Toolbar/Toolbar';
import Form from '../Form/Form';
import NextPage from '../NextPage/NextPage';
import Imprint from '../imprint/Imprint';


function App() {
  const [lang, setLang] = React.useState("de")
  const [nextPage, setNextPage] = React.useState(false)
  const setLanguage = (lang) => {
    setLang(lang)
  };

  return (
    <>
      <div className="App">
        <Toolbar lang={lang} setLanguage={setLanguage} />{
          nextPage ? <NextPage lang={lang} /> :
            <Form setNextPage={() => setNextPage(true)} lang={lang} />
        }
        <Imprint />
      </div>
    </>
  );
}

export default App;
