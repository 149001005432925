import React from 'react'
import './css.css';

const dataPolicy = () =>
    <>

        <p className='marginTop lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize18 font-family:"inherit",serif;color:#333333; bold'>Privacy Policy</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>This data privacy policy is intended to inform you about the collection, processing and storage of your personal data by mediaLab Performance GmbH Wilhelmstrasse 68, 1120 Vienna, Austria, and company register number FN 526639w (hereinafter referred to as &ldquo;mediaLab&rdquo;).</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />The data privacy provisions can be viewed, saved and printed at any time via the link &ldquo;Data privacy policy&rdquo;.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 1 Scope</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall collect, process and store your personal data in order to provide and optimise the services offered by mediaLab. mediaLab shall use the data collected for contract fulfilment and advertising purposes.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 2 Data processing as data controller</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall collect, process and store personal data from users and interested parties (collectively referred to as &ldquo;Users&rdquo;), either which they enter themselves or generated by mediaLab.Users shall be persons who are members of the online platform webinarWorld (hereinafter referred to as &ldquo;webinarWorld&rdquo;). Interested parties shall be such other persons whose data mediaLab processes as data controller. This shall include, but is not limited to, persons who visit the mediaLab website, or who request information material from mediaLab, or who make use of any other mediaLab offer.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>We shall process the following user data:</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>If contact is made via e-mail:<br /> a. E-mail address<br />b. All data voluntarily provided by the user in the e-mail.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>2. If a support ticket is submitted:<br /> a. E-mail address<br />b. All data voluntarily provided by the user in the support ticket.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>3. If the User registers for and participates in a webinar provided by mediaLab or any other user via webinarWorld:<br /> a. E-mail address<br /> b. First name<br /> c. Last name<br /> d. Browser and system data<br /> e. IP address<br /> f. Language<br /> g. Time zone<br /> h. All data entered in the chat by the participant.<br /> i. Any other data that has to be provided for registration, e.g. the telephone number.<br />j. Usage data resulting from registration for and participation in the webinar.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>4. If information material is requested:<br /> a. E-mail address<br /> b. First name<br /> c. Last name<br /> d. Browser and system data<br /> e. IP address<br /> f. Language<br /> g. Time zone<br />i. Any other data that has to be provided for registration, e.g. the telephone number.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>5. While using webinarWorld:<br /> a. All data entered by the User in their webinarWorld user account while using webinarWorld.<br />b. Usage data resulting from product use, such as access figures or application history.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall use personal data for the following purposes:<br /> 1. To enable and improve functionality and availability of webinars and functions used by users;<br /> 2. To process requests by users and interested parties;<br /> 3. To inform users and interested parties about offers, news, etc.;<br /> 4. To create analyses and compile statistics;<br /> 5. For advertisement and marketing;<br />6. For the fulfilment of contracts, including contract initiation and processing.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 3 Data processing as data processor</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall offer users of webinarWorld the opportunity to publish webinars. For this, mediaLab needs to process the data of clients and interested parties (hereinafter referred to as &ldquo;participants&rdquo;) on behalf of the user. In this case, the user shall be soley responsible for the data. The scope of the data and data processing shall be determined in our Commissioned Data Processing Agreement.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 4 Sensitive personal data</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall not, on its own initiative, process any personal data that reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, information on health or sexual orientation; unless the User concerned has provided such data themselves and expressly consented to the processing.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 5 Legal basis for processing personal data</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>a) Consent: Insofar as we ask for the Users&rsquo; consent to process personal data, Art. 6 para. 1 lit. a of the EU General Data Protection Regulation (GDPR) shall be the legal basis for the processing of personal data.<br /> b) Contract (initiation): Art. 6 para. 1 lit. b GDPR shall serve as the legal basis for the processing of personal data required for the performance of a contract to which the user is a party. This shall also apply for processing that is necessary for the implementation of pre-contractual measures.<br /> c) Legal obligation: Insofar as mediaLab is subject to comply with a legal obligation that requires the processing of personal data, Art. 6 para. 1 lit. c GDPR shall serve as the legal basis.<br />d) Protection of legitimate interests: If the data processing is necessary for the purposes of safeguarding the legitimate interests of mediaLab or a third party, except where such interests are overridden by the interests, fundamental rights or freedoms of the data subject, Art. 6 para. 1 lit. f GDPR shall serve as the legal basis for processing.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 6 Data deletion and retention period</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The personal data of the User shall be deleted or blocked as soon as the purpose of retention ceases to exist. The data may also be stored if provided for by European or national statutory regulations, legislation or other provisions to which the data controller is subject. The data shall also be blocked or deleted when a mandatory retention period as per the aformentioned standards ends, unless further retention of the data is necessary for contract conclusion or performance of a contract.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />If a User has been blocked on webinarWorld, their data shall continue to be processed insofar as necessary to prevent a re-registration. Furthermore, mediaLab shall comply with the statutory retention regulations.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 7 Recipient of the data and usage by third parties</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The initial recipient of data from users and interested parties is mediaLab.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall use the data of users and interested parties for contextual or behavioural advertising, if applicable, however they shall not use the data of the users&rsquo; clients or participants. Provider data shall be used and/or passed on for allocating advertisement as accurately as possible. In addition, the data of users and interested parties may also be used for other advertising and market research purposes.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab shall commission third parties with the fulfilment of various tasks and the associated processing of data. For this purpose, mediaLab shall transfer some or all data of users and interested parties to these third parties.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />mediaLab cooperates with mWS myWorld Solutions AG, Grazbachgasse 87-93, 8010 Graz, Austria, and with Webinaris GmbH, Bussardstrasse 5, 82166 Gr&auml;felfing, Germany, as data processors for the purpose of technical processing of webinarWorld data.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />mediaLab shall conclude suitable data processing contracts according to Art. 28 GDPR with all third parties that process the data of users and interested parties in order to prevent misuse of the data.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>In the event that mediaLab is sold, in whole or in part, or another legal succession<br />occurs, all data shall be passed on to the respective legal successor.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Personal data shall be disclosed to national investigative and judicial authorities<br /> on request, in compliance with the statutory legal regulations. Personal data shall<br /> also be disclosed to social insurance institutions in accordance with the respective<br />statutory obligations to provide information.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 8 Cookies</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Cookies are small files that allow user-related information to be stored on the<br /> User&rsquo;s computer or other devices while the User is using webinarWorld. This<br /> includes, in particular, &ldquo;session cookies&rdquo;, which allow the User to be identified<br /> while they are on the webinarWorld website. After the end of the session, some of<br /> the User&rsquo;s login data is encrypted and stored in such way that mediaLab is able to<br />recognize the User when they next visit the site.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Users can deactivate storage of cookies in their browser, restrict it to specific<br /> websites, or set up the browser so that it notifies them whenever a cookie is sent.<br /> Furthermore, Users can delete cookies from their hard drive. mediaLab shall point<br /> out to the Users that they might not be able to use webinarWorld services, either<br />at all or not fully, if cookies are deactivated.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 9 Use of social media plugins</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>webinarWorld includes social media plugins, for example from Facebook, Twitter,<br /> XING, LinkedIn, YouTube and Pinterest, such as the Facebook &ldquo;Like&rdquo; button or<br /> the Twitter button. When they are displayed, a direct connection is established<br /> between the User&rsquo;s computer and the server of the plugin provider (e.g. Facebook<br />or Twitter), which may result in the User&rsquo;s personal data being collected.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> The amount of data collected by the social media provider varies, depending on<br /> whether the User has their own profile on the respective social media platform and<br /> whether they are logged in on the social media platform while using<br /> webinarWorld. mediaLab shall point out that the User&rsquo;s IP address may still be<br />stored, even if they do not have a profile on the respective social media platform.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> webinarWorld includes plugins for the social network Facebook, 1601 South<br /> California Avenue, Palo Alto, CA 94304, USA, among others. Users can identify<br /> Facebook plugins by the Facebook logo or the &ldquo;Like&rdquo; button on webinarWorld.<br /> Users can find an overview of Facebook plugins here:<br />http://developers.facebook.com/docs/plugins/.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> When Users visit webinarWorld, the plugin will establish a direct connection<br /> between the User&rsquo;s browser and the Facebook server. mediaLab shall point out<br /> that mediaLab, as the provider of the website, has no knowledge of the content of<br /> the data transmitted or how it is used by Facebook. Further information can be<br />found in the Facebook privacy policy: http://de-de.facebook.com/policy.php .</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> If the User has a Facebook profile and is logged in there while using<br /> webinarWorld, Facebook will recognise the date and time of their visit to<br />webinarWorld, the URL, technical data such as the IP address, the web browser and the operating system used, as well as their Facebook user ID. If the User clicks on one of the social plugins, the information is sent to the User&rsquo;s Facebook account and stored there. Even if the User is not a registered Facebook user, Facebook may store the User&rsquo;s IP address.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />mediaLab shall point out that the User&rsquo;s IP address will be sent to Facebook when they visit webinarWorld, regardless of whether they have a Facebook profile.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />The consequences of the inclusion of social media plugins, as described above for Facebook, also apply correspondingly to other social media plugins included on webinarWorld, such as Twitter, XING, LinkedIn, YouTube and Pinterest. mediaLab therefore recommends that Users should also read the privacy policies of Twitter, XING, LinkedIn, YouTube and Pinterest.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 10 Tracking and analysis by Google Analytics</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab uses Google Analytics, a web analysis service of Google Inc. (&ldquo;Google&rdquo;). Google Analytics uses &ldquo;cookies&rdquo;, which are text files placed on the User&rsquo;s computer to help the website analyse how users use the site (for more information, see Section 8). webinarWorld usage information generated by the cookie will generally be transmitted to and stored by Google on servers in the United States. If the User enables IP anonymisation on webinarWorld, the User&rsquo;s IP address is generally truncated by Google in European Union member states or other countries of the European Economic Area before transmission.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Only in exceptional cases is the full IP address transmitted to the Google server in the United States and truncated there. ON behalf of mediaLab, Google will use this information to analyse the use of webinarWorld by the User, to compile reports on website activities, and to provide further services related to the use of the website and the Internet for mediaLab. The User&rsquo;s IP address transmitted by their browser for use by Google Analytics shall not be merged with other Google data.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The User can prevent cookies in their browser settings; but mediaLab hereby informs Users that they might not be able to use all of the functions of webinarWorld in full if they do so. In addition, the User can also prevent the collection by Google of the data generated by the cookie (including their IP address) and its transmission to and processing by Google by downloading and installing a browser plugin available under the following link:http://tools.google.com/dlpage/gaoptout?hl=de.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 11 International data processing</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab cooperates with capable partners within the European Union for the provision of certain tasks. The transmission of your personal and business data to member states of the European Economic Area, Switzerland and other countries with an adequate level of data protection shall not require further consent or official approval. However, if your personal and business data is to be transmitted to other countries, other than those mentioned above, we shall inform you and comply with the applicable data protection legislation, provide appropriate safeguards and enforce your rights and legal remedies.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab cooperates with Lyconet International AG, Orbi Tower, Thomas-Klestil-Platz 13, 1030 Vienna, Austria, by forwarding Users&rsquo; personal data including first and last name, telephone number and e-mail address as well as data on whether the User has viewed a webinar, to Lyconet International AG and its contractual partners, namely the respective persons, who provided the User with the webinar via a link, so that the above mentioned data can be taken over by them for the purpose of evaluation.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 12 Responsible person and data protection officer</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The responsible under data protection law is:<br /> mediaLab Performance GmbH<br /> Wilhelmstrasse 68<br /> 1120 Vienna<br /> Austria<br /><a href="mailto:support@webinarworld.com">support@webinarworld.com</a></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The data protection officer is:<br /> Peter Oskar Miller<br /> HXS GmbH<br /> Millergasse 3<br /> 1060<br /> Vienna<br /> Austria<br /><a href="data.protection@webinarworld.com">data.protection@webinarworld.com</a></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 13 Your rights according to data protection regulations and contact</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>You have the right to obtain information about the personal data about you that we process, and you may request that it be corrected, deleted or the processing restricted. Furthermore, you are entitled to object and have the right to data transfer (i.e. you are entitled to receive the data in a structured, commonly used and machine-readable format).<br /> If you are of the opinion that the processing of your personal data violates the EU General Data Protection Regulation, you have the right to lodge a complaint with the Austrian Data Protection Authority (www.dsb.gv.at) or a supervisory authority of another EU Member State.<br />If you have consented to the use of your personal data on this website, you are entitled to revoke this consent to the processing of your personal and business data at any time without giving your reasons. Such revocation shall cause any further use of the data to be forbidden. The revocation must be made in writing (by letter, fax or e-mail) and sent to the following address:</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab Performance GmbH<br /> Wilhelmstrasse 68<br /> 1120 Vienna<br /> Austria<br /><a href="mailto:support@webinarworld.com">support@webinarworld.com</a></span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Please also contact this address if you have any questions regarding the use of your data or the enforcement of your data protection rights.</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333; bold'>Clause 14 Amendments</span></p>
        <p className='marginTop marginBottom7 lineHeightNormal fontSize15 fontFamilyCalibri'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>mediaLab reserves the right to amend this data privacy policy. mediaLab encourages the users to review the online privacy policy periodically.
        mediaLab reserves the right to amend this data privacy policy. mediaLab and its contractual partners will contact you with any changes needing your additional consent regarding the privacy policy periodically or when needed.
</span></p>
        <p className='marginTop margin-bottom:8.0pt;line-height:107%;fontSize15 fontFamilyCalibri'></p>
    </>;
export default dataPolicy; 