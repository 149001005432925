import React from 'react';
import localizations from '../../localizations';
import './Form.css';
import FloatingLabelInput from 'react-floating-label-input';

let getLocalizations = (language) =>
  Object.keys(localizations).find((key) => key === language)
    ? localizations[language]
    : localizations.en;

const size = ['1', '1-10', '25-50', '>50'];
const Input = ({ label, value, onChange, empty, field }) => {
  return (
    <div className="inputContainer">
      <form>
        <FloatingLabelInput
          id={label}
          label={label}
          onChange={onChange}
          value={value}
          {...(field === 'companySize' && { list: field })}
        />
        {field === 'companySize' && (
          <datalist id="companySize">
            {size.map((size) => (
              <option value={size} key={size} />
            ))}
          </datalist>
        )}
      </form>
      {empty && value === '' && (
        <div style={{ width: '100%', height: '1px', backgroundColor: 'red' }} />
      )}
    </div>
  );
};

const order = [
  'name',
  'surname',
  'email',
  'phone',
  'company',
  'subsidiaries',
  'postalCode',
  'region',
  'companySize',
  'branche',
];

function Form({ lang, setNextPage }) {
  const urlParams = new URLSearchParams(window.location.search);
  const refferer = urlParams.get('r');

  const localizations = getLocalizations(lang);

  const [values, setValues] = React.useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    company: '',
    subsidiaries: '',
    postalCode: '',
    region: '',
    companySize: '',
    branche: '',
  });

  const [empty, setShowEmpty] = React.useState(false);

  const onChange = (field) => (value) => {
    const newValue = value.target.value;
    if (newValue !== '' && field === 'companySize' && !size.includes(newValue)) return;
    setValues((prev) => ({ ...prev, [field]: newValue }));
  };

  const postForm = () => {
    const data = {
      Referer: refferer,
      Name: values.name,
      FirstName: values.surname,
      Email: values.email,
      TelephoneNumber: values.phone,
      CompanyName: values.company,
      NumberOfSubsidiaries: values.subsidiaries,
      PostCode: values.postalCode,
      Region: values.region,
      CompanySize: values.companySize,
      Branche: values.branche,
      Lang: lang,
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    fetch('https://europe-west3-cbpartner.cloudfunctions.net/cbpartner-api', requestOptions).then(
      (data) => {
        setNextPage();
      },
    );
  };

  const submit = () => {
    if (
      Object.values(values).reduce((acc, element) => {
        return acc || element === '';
      }, false)
    ) {
      setShowEmpty(true);
    } else {
      postForm();
    }
  };

  return (
    <>
      <div className="Form">
        <h2 className="title">{localizations.header}</h2>
        <h5 className="subtitle">{localizations.subheader}</h5>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1250px',
          }}
        >
          {order.map((field) => (
            <Input
              key={field}
              empty={empty}
              value={values[field]}
              label={localizations[field]}
              onChange={onChange(field)}
              field={field}
            />
          ))}
        </div>
        {empty && (
          <p style={{ color: 'red', textAlign: 'center', fontSize: '13px' }}>
            {localizations.error}
          </p>
        )}
        <div className="button" onClick={submit}>
          {localizations.submit}
        </div>
        <div
          style={{
            width: '90%',
            paddingTop: '20px',
            fontSize: '13px',
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          {localizations.submitInfo}
        </div>
      </div>
    </>
  );
}

export default Form;
