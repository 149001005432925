import React from 'react'
import './css.css';

const test = () =>
    <>
        <p className={'fontSize18 fontFamilyCalibri margin-top:0cm;margin-bottom:8.0pt; lineHeight107 bold'}>Imprint</p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Media Proprietor, Publisher, Producer and Service Provider</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> mediaLab Performance GmbH<br /> Wilhelmstra&szlig;e 68<br /> 1120 Vienna<br />Austria</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br /> Telephone: +43 (0)1 3111888<br /> E-mail: <a href="mailto:support@webinarworld.com">support@webinarworld.com</a><br />Website: www.webinarworld.com</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>GmbH based in Vienna<br />registered under FN 526639w of the Commercial Court Vienna</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Managing Director:Kathrin Biernat</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />The technical operator of the website is Classerium A/S, Forskerparken 10, 5230 Odense M ,Denmark, registered under EU company number: DK41023414 , e-mail: info@classerium.biz, on behalf of mediaLab Performance GmbH.</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>

        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />Gender-specific pronouns &amp; political correctness</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>The fact that the feminine form is not written in this text (for example, his order, instead of his/her order), is not meant as a disrespectful act towards females. It is merely to make the text easier to read. We therefore ask our female readers to forgive us for using the simpler form and would like them to know that they are also included in every way!</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'><br />Conciliation body pursuant to regulation (EU) no 524/2013 regarding online dispute settlement in consumer affairs: http://ec.europa.eu/consumers/odr/</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#-333333;'></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>You are entitled to have your data rectified, deleted, limited and withdrawn. If you wish to exercise these rights, please contact us. If you believe that we are in breach of data protection law or that your data is being violated in any other way, please contact our Data Protection Officer. If they are unable to find a solution that you find acceptable, you can also complain to the data protection supervisory authorities. Austria&#39;s data protection authorities are responsible for us.</span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Data Protection Officer<br /> Peter Oskar Miller<br /> Millergasse 3<br /> 1060 Vienna<br />E-mail: <a href="data.protection@webinarworld.com">data.protection@webinarworld.com</a></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:7.5pt;line-height:107%;background:white;'><span className='fontSize14 font-family:"Open Sans",sans-serif;color:#333333;'>Austrian Data Protection Authorities<br /> Barichgasse 40-42<br /> 1030 Wien<br /> Phone: +43 1 521 52 25 69<br />E-mail:<a href="dsb@dsb.gv.at">dsb@dsb.gv.at</a></span></p>
        <p className='fontSize15 fontFamilyCalibri margin-top:0cm;margin-bottom:8.0pt;line-height:107%;'></p>
    </>

export default test; 