const locale = {
  en: {
    name: 'Name',
    surname: 'Surname',
    email: 'Mail',
    phone: 'Telephone number',
    company: 'Company Name',
    subsidiaries: 'Number of subsidiaries',
    postalCode: 'Post code',
    region: 'Region',
    companySize: 'Company size',
    branche: 'Business',
    header: 'Contact us!',
    subheader:
      'We will get back to you with more information asap! Discover how to grow your customer base sustainably!',
    reply1: 'Thank you!',
    reply2:
      'We will get back to you as soon as possible! Interested in more amazing shopping-deals and great experiences?',
    reply3: 'Take 3 minutes time and learn more:',
    webinarId: '4ca059bd9b4a47b6959af1b2aa2277c8',
    linkTitle: 'Watch this Webinar',
    submit: 'Submit',
    submitInfo:
      'By sending the contact form, I confirm that the data that I have voluntarily entered in the form may be processed by myWorld Austria GmbH for the purpose of establishing contact.',
    error: 'All fields must be filled',
  },
  de: {
    name: 'Name',
    surname: 'Vorname',
    email: 'E-mail Adresse',
    phone: 'Telefonnummer',
    company: 'Firmenname',
    subsidiaries: 'Anzahl der Filialen',
    postalCode: 'Postleitzahl',
    region: 'Bundesland',
    companySize: 'Mitarbeiteranzahl',
    branche: 'Branche',
    header: 'Vielen Dank für Ihr Interesse an unserem einzigartigen Partnerprogramm.',
    subheader: 'Gerne nehmen wir mit Ihnen Kontakt auf – Sie sind nur mehr wenige Klicks entfernt:',
    reply1: 'Danke für Ihre Kontaktaufnahme.',
    reply2:
      'Wir melden uns in Kürze!        Sind Sie bereits auch privat bereits Teil unserer einzigartigen Shopping-Community mit Hunderttausenden Mitgliedern? Nehmen Sie sich drei Minuten Zeit.',
    reply3: 'Hier erfahren Sie mehr:',
    webinarId: '3c871271d61b4615bad7298e9191b795',
    linkTitle: 'Schauen Sich das folgende Webinar an',
    submit: 'Anmelden',
    submitInfo:
      'Durch Absenden des Kontaktformulars bestätige ich, dass die von mir freiwillig in das Formular eigetragenen Daten zum Zweck der Kontaktaufnahme durch die myWorld Austria GmbH verarbeitet werden dürfen.',
    error: 'Alle Felder müssen vollständig ausgefüllt werden.',
  },
};

export default locale;
