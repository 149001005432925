import React from 'react';
import './Imprint.css';
import DataPolicy from './policy/DataPolicy';
import ImprintPolicy from './policy/Imprint';
import Terms from './policy/Terms';

function Imprint() {

    const [modalShown, setModalShown] = React.useState(null);

    return (
        <>
            <div className="imprint">
                <div className="imprintElement"
                    onClick={() => setModalShown("imprint")} >
                    Imprint
                     </div>
                <div className="imprintElement"
                    onClick={() => setModalShown("data")} >
                    Data Policy
            </div>
                <div className="imprintElement"
                    onClick={() => setModalShown("terms")} >
                    Terms of Service
            </div>
            </div>
            {
                modalShown &&
                <div>
                    <div className="overlay"
                        onClick={() => setModalShown(null)} />
                    <div className="termsOverlay" >
                        <div className="closeButton"
                            onClick={() => setModalShown(null)} />
                        {modalShown === 'data' && <DataPolicy />}
                        {modalShown === 'imprint' && <ImprintPolicy />}
                        {modalShown === 'terms' && <Terms />}
                    </div>
                </div>

            }
        </>
    );
}

export default Imprint;