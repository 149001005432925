import React from 'react';
import './Toolbar.css';
import logo from '../../assets/cbw-retail-logo.svg';
import enFlag from '../../assets/en.png';
import deFlag from '../../assets/de.png';

const LangItem = ({ lang, selectLanguage, selected }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      color: '#005551',
      alignItems: 'center',
      fontSize: '15px',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingRight: '20px',
      fontWeight: selected ? 'bold' : 'normal',
      cursor: 'pointer',
    }}
    onClick={() => selectLanguage()}
  >
    <img
      src={langConfig[lang].img}
      style={{ width: '22px', height: '20px', marginRight: '5px' }}
      alt=""
    />
    {langConfig[lang].title}
  </div>
);

const langConfig = {
  en: {
    img: enFlag,
    title: 'English',
  },
  de: {
    img: deFlag,
    title: 'Deutsch',
  },
};

function Toolbar({ lang, setLanguage }) {
  const otherLang = lang === 'en' ? 'de' : 'en';

  const selectLanguage = (lang) => {
    if (!selectionInProgress) {
      setSelectionInProgress(true);
    } else {
      setSelectionInProgress(false);
      setLanguage(lang);
    }
  };

  const [selectionInProgress, setSelectionInProgress] = React.useState(false);

  return (
    <>
      <div className="Toolbar" onMouseLeave={() => selectionInProgress && selectLanguage(lang)}>
        <img src={logo} className="logo" alt="" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: '30px',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <LangItem lang={lang} selected={true} selectLanguage={() => selectLanguage(lang)} />
            {selectionInProgress && (
              <LangItem
                lang={otherLang}
                selected={false}
                selectLanguage={() => selectLanguage(otherLang)}
              />
            )}
          </div>
          <div
            onClick={() => selectLanguage(lang)}
            className={selectionInProgress ? 'arrow-up' : 'arrow-down'}
          />
        </div>
      </div>
      <div className="placeholder" />
    </>
  );
}

export default Toolbar;
